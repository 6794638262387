@import "_app.scss";


//colors
$white: #fff;
$color_mine_shaft_approx: #333232;
$color_star_dust_approx: #9d9d9d;
$color_alto_approx: #ddd;
$color_bali_hai_approx: #8399b3;
$color_silver_sand_approx: #c1c2c1;
$color_dove_gray_approx: #6d6d6d;
$color_tapa_approx: #757575;

//fonts
$font_0: "Myriad Pro";
$font_1: "Arial";
$font_2: "Myriad Pro";
$font_3: "Arial";

//urls
$url_0: image-url("../img/mobilemy/body-bg.png");
$url_1: image-url("../img/mobilemy/bg-tick.jpg");
$url_2: image-url("../img/mobilemy/btn-download-app.png");

//@extend-elements
//original selectors
//.content input[type="text"], .content input[type="password"]
%extend_1 {
  width: 449px;
  height: 40px;
  padding: 20px 30px;
  border: 1px solid $color_bali_hai_approx;
  //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
  border-radius: 5px;
  //Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
  box-shadow: inset 0 0 15px 2px $color_silver_sand_approx;
  display: block;
  line-height: 34px;
  font-size: 35px;
  color: $color_dove_gray_approx;
}


body {
  font-size: 62.5%;
  background: $white;
  width: 100%;
  height: 100%;
  &.landing-page {
    background: $url_0 top left repeat-x;
  }
}
button {
  border: none;
}
table {
  padding: 0;
  border-collapse: collapse;
  background: $white;
  td {
    padding: 0;
  }
}
html {
  width: 100%;
  height: 100%;
}
.forAlignment {
  width: 100%;
  height: 100%;
}
.wrapperContainer {
  width: 603px;
  height: 100%;
  padding: 0 19px;
  overflow: hidden;
  margin: 0 auto;
  position: relative;
}
.content {
  width: 100%;
  background: $white;
  .logo {
    margin: 0 0 40px !important;
  }
  form {
    width: 100%;
    float: left;
    margin: 0;
  }
  .form-row {
    margin: 0 0 20px;
    label {
      position: absolute !important;
      top: -9999em !important;
    }
  }
  .type-hidden {
    position: absolute !important;
    top: -9999em !important;
  }
  .type-password {
    margin: 0 0 40px;
  }
  input[type="text"] {
    @extend %extend_1;
  }
  input[type="password"] {
    @extend %extend_1;
  }
}
.positionOff {
  position: absolute !important;
  top: -9999em !important;
}
.ticklist {
  width: 100%;
  padding: 0;
  float: left;
  margin: 0 0 20px;
  list-style: none;
  li {
    height: 38px;
    padding: 5px 0 0 50px;
    overflow: hidden;
    margin: 0 0 40px;
    line-height: 32px;
    position: relative;
    white-space: nowrap;
    font-family: $font_0, $font_1;
    font-size: 36px;
    color: $color_mine_shaft_approx;
    span {
      width: 34px;
      height: 29px;
      display: block;
      overflow: hidden;
      position: absolute;
      top: 2px;
      left: 0;
      background: $url_1 no-repeat 0 0;
    }
  }
}
.controls {
  width: 100%;
  float: left;
  overflow: hidden;
  text-align: center;
  li {
    float: none;
    display: inline;
    text-align: center;
    font-size: 26px;
    color: $color_star_dust_approx;
    a {
      padding: 32px;
      font-size: 26px;
      color: $color_star_dust_approx;
      line-height: 29px;
    }
    &.sep-or {
      width: 480px;
      height: 20px;
      border-top: 1px solid $color_alto_approx;
      display: block;
      margin: 0 auto;
      span {
        width: 34px;
        padding: 3px 6px;
        display: block;
        margin: 0 auto;
        position: relative;
        top: -16px;
        text-align: center;
        text-transform: uppercase;
        font-size: 23px;
        color: $color_star_dust_approx;
        background: $white;
      }
    }
    &.site {
      margin-right: 35px;
    }
    &:first-child a {
      padding: 0;
    }
  }
}
.btn {
  height: 54px;
  display: block;
  margin: 0 0 20px;
  * {
    height: 54px;
    display: block;
    line-height: 50px;
    font-size: 34px;
  }
}
.landing-page {
  table {
    background: none;
  }
  .header {
    display: none;
  }
  .content {
    background: none;
    #pageContent {
      width: 523px;
      padding: 30px 40px 42px;
      //Instead of the line below you could use @include border-top-left-radius($radius)
      border-top-left-radius: 5px;
      //Instead of the line below you could use @include border-top-right-radius($radius)
      border-top-right-radius: 5px;
      overflow: hidden;
      background: $white;
    }
    .logo {
      width: 100%;
      float: left;
      overflow: hidden;
      margin: 0 auto 15px;
      text-indent: -9999em;
    }
    .greybutton2 {
      display: none;
    }
    button * {
      display: none;
    }
  }
  .strapline {
    width: 100%;
    float: left;
    margin: 0 0 50px;
    text-align: center;
    font-family: $font_2, $font_3;
    font-size: 17px;
    color: $color_tapa_approx;
  }
  .forAlignment td {
    height: 100%;
    padding: 0;
    vertical-align: middle;
  }
}
#content .wrapperContainer {
  background: none;
}
.btn-downloadApp * {
  background: $url_2 no-repeat 0 -9999em;
}
.iphone .btn-downloadApp * {
  background-position: center 0;
}
.android .btn-downloadApp * {
  background-position: center -84px;
}
.blackberry .btn-downloadApp * {
  background-position: center -168px;
}
